<template>
  <div class="login">
    <v-container class="pa-0 mt-sm-4 mt-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-form ref="form" lazy-validation>
            <v-row>
              <!-- Authorization -->
              <v-col cols="12" lg="4">
                <h1 class="header-h1">Authorization</h1>

                <!-- We have commissioned a new system, If you cannot log into your account, please -->
                <h6 class="b-body-1 mt-1">
                  We have commissioned a new system, If you cannot log into your
                  account, please
                </h6>

                <!-- restore your password -->
                <router-link
                  class="b-body-1 primary--text text-decoration-none"
                  :to="{ name: 'PasswordRecoveryPage' }"
                >
                  restore your password
                </router-link>
              </v-col>

              <!-- Password changed successfully -->
              <v-col v-if="justReseted" cols="12">
                <!-- Password changed successfully -->
                <div class="mx-0 mb-2">
                  <div class="success50 mx-n4 mx-md-n6">
                    <v-card
                      class="transparent px-4 px-md-6 py-2"
                      width="100%"
                      flat
                      tile
                    >
                      <!-- Password changed successfully -->
                      <h5 class="header-h5 success500--text">
                        Password changed successfully
                      </h5>

                      <p class="b-body-500 success600--text pa-0 ma-0">
                        Please Log in with the new password.
                      </p>
                    </v-card>
                  </div>
                </div>
              </v-col>

              <!-- ID Number -->
              <v-col cols="12">
                <v-card class="mt-n4" max-width="170" flat>
                  <p class="label-text mb-1">ID number</p>

                  <v-text-field
                    v-model="idnumber"
                    :rules="idnumberRules"
                    class="inputPrice"
                    type="number"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length==13) return false;"
                    onkeydown="return event.keyCode !== 69"
                    height="48px"
                    full-width
                    outlined
                    dense
                    required
                  ></v-text-field>

                  <div v-if="alreadyRegistered" class="mt-n6">
                    <p class="b-body-1 gray700--text">
                      Unknown ID number. Please
                      <router-link
                        class="b-body-1 primary--text text-decoration-none"
                        :to="{ name: 'RegistrationPage' }"
                      >
                        Register
                      </router-link>
                    </p>
                  </div>
                </v-card>
              </v-col>

              <!-- Password -->
              <v-col cols="12">
                <v-card class="mt-n4" width="362" flat>
                  <div class="d-flex">
                    <p class="label-text mb-1">Password</p>

                    <v-spacer />

                    <router-link
                      class="b-body-1 primary--text text-decoration-none"
                      :to="{ name: 'PasswordRecoveryPage' }"
                    >
                      Forgot password?
                    </router-link>
                  </div>

                  <v-text-field
                    @click:append="showPassword = !showPassword"
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="passwordRules"
                    height="48px"
                    full-width
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-card>

                <!-- Invalid credentials -->
                <v-card
                  v-if="idnumberHasError || passwordHasError"
                  class="mt-n4"
                  width="362"
                  flat
                >
                  <p
                    v-if="accountLocked"
                    class="b-body-1 error500--text mt-4 mb-5"
                  >
                    Your account has been locked out. Please contact a Customer
                    Care Agent on 010 442 6722
                  </p>

                  <p v-else class="b-body-1 error500--text mt-4 mb-5">
                    Invalid credentials
                  </p>
                </v-card>
              </v-col>

              <!-- Login -->
              <v-col cols="12" class="mt-n4">
                <v-card width="362" flat>
                  <!-- Desktop -->
                  <v-btn
                    @click="validate"
                    class="b-button primary text-capitalize hidden-xs-only"
                    height="48"
                    width="175"
                    elevation="0"
                    :disabled="!readyToSubmit"
                  >
                    Log In
                  </v-btn>

                  <!-- Mobile -->
                  <v-btn
                    @click="validate"
                    class="b-button primary text-capitalize hidden-sm-and-up"
                    height="48"
                    width="100%"
                    elevation="0"
                    :disabled="!readyToSubmit"
                  >
                    Log In
                  </v-btn>
                </v-card>

                <div class="d-flex mt-5">
                  <p class="b-body-1">Don’t have an account?</p>

                  <router-link
                    class="b-body-1 primary--text text-decoration-none ml-2"
                    :to="{ name: 'RegistrationPage' }"
                  >
                    Register
                  </router-link>
                </div>
              </v-col>

              <!-- Overlay -->
              <v-overlay :value="overlay" z-index="10">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-row>
          </v-form>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

// import InnerCard from "@/components/shared/cards/InnerCard";

export default {
  // components: { InnerCard },

  name: "LimeLoansLogin",

  data() {
    return {
      overlay: false,
      readyToSubmit: false,

      // redirect
      redirect: "/dashboard/my-loan",

      // ID Number
      idnumber: "",
      idnumberRules: [
        (v) => !!v || "",
        (v) => /[0-9]{13}/.test(v) || "ID number is incorrect",
        (v) => v.length == 13 || "ID number is incorrect",
        (v) => (!!v && !this.idnumberHasError) || this.idnumberErrorMessage,
      ],
      idnumberHasError: false,
      idnumberErrorMessage: "ID number is incorrect",

      // password
      password: "",
      passwordRules: [
        (v) => !!v || "",
        (v) => (8 <= v.length && v.length <= 30) || "",
        (v) => (!!v && !this.passwordHasError) || this.passwordErrorMessage,
      ],
      passwordHasError: false,
      passwordErrorMessage: "Incorrect password. Please try again",

      showPassword: false,
      alreadyRegistered: false,

      justReseted: false,

      // accountLocked
      accountLocked: false,
    };
  },

  computed: {
    ...mapState({
      auth: "auth",
      list: "list",
      marketing: "marketing",
      passwordRecovery: "passwordRecovery",
    }),
  },

  created() {
    let parameters = this.$route.query;

    // console.log("parameters", parameters);
    // if (this.marketingCheckSessionExpired()) {
    //   console.log("Expired: Yes!");
    // } else {
    //   console.log("Expired: Nope!");
    // }

    // checkForMarketingQuery
    /* if (this.checkForMarketingQuery(parameters)) {
      this.resetMarketingState();
      
      this.$cookies.remove("traffic_source_data");

      // console.log("parameters @@@", parameters);

      this.setForMarketingQuery(parameters);

      // marketingSetSessionExpires
      this.marketingSetSessionExpires();
    } */

    if ("redirect" in parameters) {
      this.redirect = parameters.redirect;

      if (
        this.redirect == "undefined" ||
        this.redirect == "/login" ||
        this.redirect == "/"
      ) {
        this.redirect = "/dashboard/my-loan";
      }
    }

    if (this.passwordRecovery.justReseted) {
      this.justReseted = true;
      this.setJustReseted(false);
    }

    this.setApiLists();
    // this.resetLoginState();

    this.$helpers.resetErrorHandlerState();
  },

  watch: {
    // idnumber
    idnumber() {
      if (this.idnumberHasError) {
        this.idnumberHasError = false;
        this.idnumberErrorMessage = "ID number is incorrect";

        this.passwordHasError = false;
        this.passwordErrorMessage = "Incorrect password. Please try again";
      }

      this.setReadyToSubmit();
    },

    // password
    password() {
      if (this.passwordHasError) {
        this.idnumberHasError = false;
        this.idnumberErrorMessage = "ID number is incorrect";

        this.passwordHasError = false;
        this.passwordErrorMessage = "Incorrect password. Please try again";
      }

      this.setReadyToSubmit();
    },
  },

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    ...mapMutations({
      setLoggedIn: "auth/setLoggedIn",
      setSessionKey: "auth/setSessionKey",
      setSessionExpires: "auth/setSessionExpires",
      setIdNumber: "auth/setIdNumber",
      setUser: "auth/setUser",

      resetTruidState: "truid/resetState",

      setListDefaultState: "list/setDefaultState",
      setLists: "list/setLists",
      setSessionExpiresInAnHour: "list/setSessionExpiresInAnHour",
      resetListState: "list/resetState",

      setJustReseted: "passwordRecovery/setJustReseted",

      // marketing
      setLeadId: "marketing/setLeadId",
      setUTMSource: "marketing/setUTMSource",
      setUTMMedium: "marketing/setUTMMedium",
      setUTMCampaign: "marketing/setUTMCampaign",
      setUTMContent: "marketing/setUTMContent",
      setUTMTerm: "marketing/setUTMTerm",
      setUTMWebmasterId: "marketing/setUTMWebmasterId",
      setClickId: "marketing/setClickId",
      setGClid: "marketing/setGClid",
      setMindboxClickId: "marketing/setMindboxClickId",

      marketingSetSessionExpires: "marketing/setSessionExpires",

      resetMarketingState: "marketing/resetState",

      setCurrentState: "createLoanApplication/setCurrentState",
    }),

    ...mapGetters({
      marketingCheckSessionExpired: "marketing/checkSessionExpired",
    }),

    validate() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.loginUser();
      }
    },

    // setReadyToSubmit
    setReadyToSubmit() {
      if (
        this.idnumber === "" ||
        this.idnumber === null ||
        this.password === "" ||
        this.password === null ||
        (this.$refs.form && !this.$refs.form.validate())
      ) {
        this.readyToSubmit = false;
      } else {
        this.readyToSubmit = true;
      }
    },

    async loginUser() {
      this.overlay = true;

      try {
        var reqData = {
          idnumber: this.idnumber,
          password: this.password,
        };

        if (this.marketingCheckSessionExpired()) {
          this.resetMarketingState();

          this.$cookies.remove("traffic_source_data");
        } else {
          reqData = await this.addMarketingToReq(reqData);
        }

        // console.log("reqData", reqData);

        const res = await this.axios.post("login", reqData);

        if (res.status == 200) {
          const data = res.data;

          this.setLoggedIn(true);
          this.setSessionKey(data.session_key);
          this.setSessionExpires(data.session_expires);

          this.setIdNumber(this.idnumber);

          await this.getApiUserDetails();


          // this section is to check for the new auto approval *********************************
          // ************************************************************
          console.log(this.auth.user.latest_bank_statement.require_new)
          if(this.auth.user.latest_bank_statement.require_new == true || this.auth.user.recent_affordability.requires_update == true){

            this.$router.push({ name: "DashboardMyLoanPage" });
            return;

          }else {

            const incomes = this.auth.user.incomes
            const expenses = this.auth.user.expenses

            // Send loan application and Check if auto approval is available
            var reqData = {
              idnumber: this.idnumber,
              session_key: data.session_key,
              loan_amount: this.auth.user.recent_affordability.loan_amount + 500,
              use_existing_bank_statement: true,
              incomes,
              expenses,
            };

            if (this.marketingCheckSessionExpired()) {
              this.resetMarketingState();

              this.$cookies.remove("traffic_source_data");
            } else {
              reqData = await this.addMarketingToReq(reqData);
            }

            try {
              var resnewapplication = await this.axios.post("new-application", reqData);
              if (resnewapplication.status == 200) {
                const newapplicatondata = resnewapplication.data;
                
                var reqCheckApprovalData = {
                  idnumber: this.idnumber,
                  session_key: data.session_key,
                  application_id: newapplicatondata.application_id
                }

                var resnewapprovaldata = await this.axios.post("client-application-auto-approval-availability", reqCheckApprovalData);
                if (resnewapprovaldata.status == 200) {
                  var newapprovaldata = resnewapprovaldata.data;

                  if(newapprovaldata.auto_approval_available == true){
                    var reqCheckApprovalData = {
                      idnumber: this.idnumber,
                      session_key: data.session_key,
                      application_id: newapprovaldata.application_id
                    }

                    var resapprovalrequestdata = await this.axios.post("client-application-request-auto-approve", reqCheckApprovalData);
                    if (resapprovalrequestdata.status == 200) {
                      var approvalrequestdata = resapprovalrequestdata.data;

                      if(approvalrequestdata.approved == true){
                        this.$router.push({ name: "DashboardSigningLoanAgreementPage" });
                        return;
                      }else{
                        this.$router.push({ name: "DashboardMyLoanPage" });
                        return;
                      }
                      
                    }
                  }else{
                    this.$router.push({ name: "DashboardMyLoanPage" });
                    return;
                  }
                }else{
                  this.$router.push({ name: "DashboardMyLoanPage" });
                  return;
                }

                this.resetMarketingState();
                this.$cookies.remove("traffic_source_data");

              }else{
                this.$router.push({ name: "DashboardMyLoanPage" });
                return;
              }
            } catch (error) {
              this.$router.push({ name: "DashboardMyLoanPage" });
              return;
            }

          } 
          // this section is to check for the new auto approval *********************************
          
          this.resetTruidState();

          if (
            this.auth &&
            this.auth.user &&
            this.auth.user.banking_details &&
            this.auth.user.banking_details.bank_name &&
            this.auth.user.banking_details.account_number &&
            this.auth.user.banking_details.bank_name == "Standard Bank" &&
            this.auth.user.banking_details.account_number == "123456789"
          ) {
            this.$router.push({ name: "DashboardMyLoanPage" });
          } else if (
            !this.auth.user.has_banking ||
            !this.auth.user.has_employment ||
            !this.auth.user.has_next_of_kin
          ) {
            this.$router.push({ name: "RegistrationPage" });
          } else {
            this.$router.push(this.redirect);
          }
        }
      } catch (error) {
        let data = {};
        
        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401 || status == 422) {
          this.accountLocked = false;

          if ("idnumber" in data) {
            this.idnumberHasError = true;

            if (data.idnumber[0] == "Account locked") {
              this.accountLocked = true;
            }
          }

          if ("id_number" in data) {
            this.idnumberHasError = true;

            if (data.id_number[0] == "Account locked") {
              this.accountLocked = true;
            }
          }

          if ("password" in data) {
            this.passwordHasError = true;
          }

          this.idnumberHasError = true;
          this.idnumberErrorMessage = "";

          this.passwordHasError = true;
          this.passwordErrorMessage = "";

          this.setReadyToSubmit();
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // setApiLists
    async setApiLists() {
      // console.log(this.list.sessionExpires);

      if (this.list.sessionExpires != null) {
        var now = this.moment();
        var sessionExpires = this.moment(this.list.sessionExpires);
        const isAfter = this.moment(now).isAfter(sessionExpires);

        if (isAfter) {
          this.setSessionExpiresInAnHour();

          this.resetListState();
          this.setListDefaultState();

          await this.getApiLists();
        }
      } else {
        this.setSessionExpiresInAnHour();

        this.resetListState();
        this.setListDefaultState();

        await this.getApiLists();
      }
    },

    // getApiLists
    async getApiLists() {
      try {
        const res = await this.axios.post("lists");

        if (res.status == 200) {
          const data = res.data;

          // console.log(data);

          this.setLists(data);
        }
      } catch (error) {
        //
      }
    },

    
    // checkForMarketingQuery
    checkForMarketingQuery(parameters) {
      if (
        "lead_id" in parameters ||
        "utm_source" in parameters ||
        "utm_medium" in parameters ||
        "utm_campaign" in parameters ||
        "utm_content" in parameters ||
        "utm_term" in parameters ||
        "webmaster_id" in parameters ||
        "clickid" in parameters ||
        "gclid" in parameters ||
        "mindbox_click_id" in parameters
      ) {
        return true;
      }

      return false;
    },

    // setForMarketingQuery
    setForMarketingQuery(parameters) {
      // lead_id
      if ("lead_id" in parameters) {
        this.setLeadId(parameters.lead_id);
      }

      // utm_source
      if ("utm_source" in parameters) {
        this.setUTMSource(parameters.utm_source);
      }

      // utm_medium
      if ("utm_medium" in parameters) {
        this.setUTMMedium(parameters.utm_medium);
      }

      // utm_campaign
      if ("utm_campaign" in parameters) {
        this.setUTMCampaign(parameters.utm_campaign);
      }

      // utm_content
      if ("utm_content" in parameters) {
        this.setUTMContent(parameters.utm_content);
      }

      // utm_term
      if ("utm_term" in parameters) {
        this.setUTMTerm(parameters.utm_term);
      }

      // webmaster_id
      if ("webmaster_id" in parameters) {
        this.setUTMWebmasterId(parameters.webmaster_id);
      }

      // clickid
      if ("clickid" in parameters) {
        this.setClickId(parameters.clickid);
      }

      // gclid
      if ("gclid" in parameters) {
        this.setGClid(parameters.gclid);
      }

      // mindbox_click_id
      if ("mindbox_click_id" in parameters) {
        this.setMindboxClickId(parameters.mindbox_click_id);
      }
    },

    // addMarketingToReq
    async addMarketingToReq(reqData) {
      // lead_id
      if (this.marketing.lead_id != "") {
        reqData.lead_id = this.marketing.lead_id;
      }

      // utm_source
      if (this.marketing.utm_source != "") {
        reqData.utm_source = this.marketing.utm_source;

        reqData.ip_address = await this.getIPAddress();
        reqData.user_agent = await this.getUserAgent();
      }

      // utm_medium
      if (this.marketing.utm_medium != "") {
        reqData.utm_medium = this.marketing.utm_medium;
      }

      // utm_campaign
      if (this.marketing.utm_campaign != "") {
        reqData.utm_campaign = this.marketing.utm_campaign;
      }

      // utm_content
      if (this.marketing.utm_content != "") {
        reqData.utm_content = this.marketing.utm_content;
      }

      // utm_term
      if (this.marketing.utm_term != "") {
        reqData.utm_term = this.marketing.utm_term;
      }

      // webmaster_id
      if (this.marketing.webmaster_id != "") {
        reqData.webmaster_id = this.marketing.webmaster_id;
      }

      // clickid
      if (this.marketing.clickid != "") {
        reqData.clickid = this.marketing.clickid;
      }

      // gclid
      if (this.marketing.gclid != "") {
        reqData.gclid = this.marketing.gclid;
      }

      // mindbox_click_id
      if (this.marketing.mindbox_click_id != "") {
        reqData.mindbox_click_id = this.marketing.mindbox_click_id;
      }

      return reqData;
    },

    // getIPAddress
    async getIPAddress() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        const ipAddress = data.ip;

        return ipAddress;
      } catch (error) {
        console.error("Error fetching IP address: " + error.message);

        return "127.0.0.1";
      }
    },

    // getUserAgent
    getUserAgent() {
      return navigator.userAgent;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
